import { Item } from '../types';
import { FieldTypes, WebEntity } from './types';

const ItemEntity: WebEntity<Item> = {
  name: 'item',
  endpoint: 'item',
  referenceColumn: '_id',

  tableColumns: [
    {
      name: 'ID',
      selector: '_id',
      columnType: 'text',
      omitExport: true,
      omit: true
    },
    {
      name: 'Material',
      selector: 'material',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      columnType: 'text',
      wrap: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      columnType: 'text'
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      columnType: 'text'
    }
  ],

  fields: [
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      type: FieldTypes.Text,
      required: true
    }
  ],

  editableFields: [
    {
      name: 'Material',
      selector: 'material',
      type: FieldTypes.Text,
      required: true,
      disabled: true
    },
    {
      name: 'Texto Breve Material',
      selector: 'textoBreveMaterial',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Grupo Articulos',
      selector: 'grupoArticulos',
      type: FieldTypes.Text,
      required: true
    },
    {
      name: 'Unidad Medida Base',
      selector: 'unidadMedidaBase',
      type: FieldTypes.Text,
      required: true
    }
  ]
};

export const ITEM_COLUMNS = {
  material: 'Material',
  textoBreveMaterial: 'Texto Breve Material',
  grupoArticulos: 'Grupo Articulos',
  unidadMedidaBase: 'Unidad Medida Base'
};

export default ItemEntity;
