import axios, { AxiosResponse } from 'axios';
import * as E from '../entities';
import { WebEntityName } from '../entities/types';
import { AppDispatch, AppGetState } from '../store';
import { tokenConfig } from '../utils/header';
import { REFRESH_ENTITY } from '../reducers/types';
import { getEntitiesAction, voidEntitiesAction, setEntityLoading } from './entitiesActions';
import { handleError } from '../components/PopAlert';
import { cleanTextDate } from '../utils/utils';
import { DiasVenta, Inventario, PerfilEnvejecimiento, SimpleObject, Sku } from '../types';

export const cleanSelectedEntities =
  (entityName: WebEntityName) => async (dispatch: AppDispatch) => {
    dispatch({
      type: REFRESH_ENTITY,
      payload: null,
      entityName: entityName
    });
  };

// REFRESHERS

const refreshInventario =
  (entity: SimpleObject, idDocumento: string) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      entity.name as WebEntityName,
      `${entity.endpoint}/${idDocumento}`,
      {}
    )()(dispatch, getState);

const refreshEntity =
  (entity: SimpleObject, serverSidePagination?: boolean, query?: SimpleObject) =>
  async (dispatch: AppDispatch, getState: AppGetState) =>
    await getEntitiesAction(
      entity.name as WebEntityName,
      entity.endpoint,
      query ? { query } : {}
    )(serverSidePagination ? 1 : undefined)(dispatch, getState);

/**
 * Add Inventario Criticidad
 *
 * @param inventarioCriticidad: Array<Inventario>
 */
export type ProcessProgressData = {
  percentage: number;
  data: SimpleObject;
  message: string;
  done: boolean;
} | null;

/**
 * Add Inventario
 *
 */
export const ingresarInventario =
  (
    inventarioEntity: SimpleObject,
    documentoEntity: SimpleObject,
    inventario: Array<Inventario>,
    date: Date,
    zona: string,
    callback: (res: AxiosResponse<ProcessProgressData> | null) => void,
    idComentario: string
  ) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    let processedPercentageInterval: NodeJS.Timeout | undefined;

    const cleanLocalInterval = () => {
      if (processedPercentageInterval) {
        clearInterval(processedPercentageInterval);
      }
    };

    try {
      const { data: processUUID } = await axios.get(
        `/api/${inventarioEntity.endpoint}/newProcessUUID`,
        tokenConfig(getState)
      );

      axios
        .post(
          `/api/${inventarioEntity.endpoint}`,
          {
            data: inventario,
            fechaDocumento: cleanTextDate(date),
            zona,
            idComentario,
            massiveLoad: true,
            processUUID
          },
          tokenConfig(getState)
        )
        .catch(function (error) {
          cleanLocalInterval();
          callback(null);
          handleError({ error, entityName: inventarioEntity.name, dispatch });
        });

      const getProcessedPercentage = async () => {
        const progressRes = await axios.get(
          `/api/${inventarioEntity.endpoint}/checkLoadDataProgress/${processUUID}`,
          tokenConfig(getState)
        );

        // NOTE: When there is not progress information
        // OR When the process is complete!
        if (!progressRes.data || progressRes.data.done) {
          await refreshEntity(documentoEntity)(dispatch, getState);
          cleanLocalInterval();
          callback(progressRes);
          return;
        }

        // NOTE: When IS IN PROGRESS
        callback(progressRes);
      };

      processedPercentageInterval = setInterval(getProcessedPercentage, 2000);
    } catch (error) {
      cleanLocalInterval();
      callback(null);
      handleError({ error, entityName: inventarioEntity.name, dispatch });
    }
  };

export const mergeSku =
  (skuData: Array<Sku>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/sku/merge`, skuData, tokenConfig(getState));
      await refreshEntity(E.SkuEntity, true)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.SkuEntity.name, dispatch });
    }
  };

export const diasVentaMassiveLoad =
  (diasVentaData: Array<DiasVenta>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/diasVenta/massiveLoad`,
        diasVentaData,
        tokenConfig(getState)
      );
      await refreshEntity(E.DiasVentaEntity, true)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.DiasVentaEntity.name, dispatch });
    }
  };

export const perfilEnvejecimientoMassiveLoad =
  (perfilEnvejecimientoData: Array<PerfilEnvejecimiento>) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(
        `/api/perfilEnvejecimiento/massiveLoad`,
        perfilEnvejecimientoData,
        tokenConfig(getState)
      );
      await refreshEntity(E.PerfilEnvejecimientoEntity, true)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.PerfilEnvejecimientoEntity.name, dispatch });
    }
  };

export const itemSociedadMassiveLoad =
  (data: Array<SimpleObject>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/itemSociedad/massiveLoad`, data, tokenConfig(getState));
      await refreshEntity(E.ItemSociedadEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ItemSociedadEntity.name, dispatch });
    }
  };

export const itemMassiveLoad =
  (data: Array<SimpleObject>) => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const res = await axios.post(`/api/item/massiveLoad`, data, tokenConfig(getState));
      await refreshEntity(E.ItemEntity)(dispatch, getState);
      return res;
    } catch (error) {
      handleError({ error, entityName: E.ItemEntity.name, dispatch });
    }
  };

export const fetchDocumentInventarioCriticidad =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading('inventarioCriticidad'));

    try {
      voidEntitiesAction(E.InventarioCriticidadEntity.name as WebEntityName)()(dispatch);
      await refreshInventario(E.InventarioCriticidadEntity, idDocumento)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InventarioCriticidadEntity.name, dispatch });
    }
  };

export const refreshConteoCriticidadLog =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(`/api/inventarioCriticidad/log/refresh`, {}, tokenConfig(getState));
      await refreshEntity(E.ConteoCriticidadLogEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.ConteoCriticidadLogEntity.name, dispatch });
    }
  };

export const refreshReporteInconsistenciaFechaLog =
  () => async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      await axios.post(
        `/api/inventarioCriticidad/inconsistenciaFechaLog/refresh`,
        {},
        tokenConfig(getState)
      );
      await refreshEntity(E.InconsistenciaFechaLogEntity)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InconsistenciaFechaLogEntity.name, dispatch });
    }
  };

export const fetchDocumentInventarioEnvases =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading('inventarioEnvases'));

    try {
      voidEntitiesAction(E.InventarioEnvasesEntity.name as WebEntityName)()(dispatch);
      await refreshInventario(E.InventarioEnvasesEntity, idDocumento)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InventarioEnvasesEntity.name, dispatch });
    }
  };

export const fetchDocumentInventarioPallet =
  (idDocumento: string) => async (dispatch: AppDispatch, getState: AppGetState) => {
    dispatch(setEntityLoading('inventarioPallet'));

    try {
      voidEntitiesAction(E.InventarioPalletEntity.name as WebEntityName)()(dispatch);
      await refreshInventario(E.InventarioPalletEntity, idDocumento)(dispatch, getState);
    } catch (error) {
      handleError({ error, entityName: E.InventarioPalletEntity.name, dispatch });
    }
  };
