import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../reducers/types';
import { AppState } from '../store';
import { TipoUsuarioValues } from '../types';
import GenericDropdownMenu from './generics/GenericDropdownMenu/GenericDropdownMenu';

const adminTree = [
  {
    id: 1,
    label: 'Mantenedores',
    isOpened: false,
    icon: 'mantainer',
    children: [
      {
        id: 1001,
        label: 'Usuarios',
        isOpened: false,
        href: '/mantenedores/Usuario'
      },
      {
        id: 1002,
        label: 'Tipo Usuario',
        isOpened: false,
        href: '/mantenedores/TipoUsuarios'
      },
      {
        id: 1003,
        label: 'Estado Documento',
        isOpened: false,
        href: '/mantenedores/EstadoDocumentos'
      },
      {
        id: 1004,
        label: 'SKU',
        isOpened: false,
        href: '/mantenedores/SKU'
      },
      {
        id: 1005,
        label: 'Item Sociedad',
        isOpened: false,
        href: '/mantenedores/itemSociedad'
      },
      {
        id: 1006,
        label: 'Item',
        isOpened: false,
        href: '/mantenedores/item'
      },
      {
        id: 1007,
        label: 'Sociedad',
        isOpened: false,
        href: '/mantenedores/sociedad'
      },
      {
        id: 1008,
        label: 'SKU Factor',
        isOpened: false,
        href: '/mantenedores/SKUFactor'
      },
      {
        id: 1009,
        label: 'Perfil Envejecimiento',
        isOpened: false,
        href: '/mantenedores/PerfilEnvejecimiento'
      },
      {
        id: 1010,
        label: 'Dias Venta',
        isOpened: false,
        href: '/mantenedores/DiasVentas'
      },
      {
        id: 1011,
        label: 'Centros',
        isOpened: false,
        href: '/mantenedores/Centros'
      },
      {
        id: 1012,
        label: 'Bodegas Usuarios',
        isOpened: false,
        href: '/mantenedores/BodegasUsuarios'
      },
      {
        id: 1013,
        label: 'Activos',
        isOpened: false,
        href: '/mantenedores/Activos'
      },
      {
        id: 1014,
        label: 'Tipo Activo',
        isOpened: false,
        href: '/mantenedores/TipoActivos'
      },
      {
        id: 1015,
        label: 'Nomenclaturas',
        isOpened: false,
        href: '/mantenedores/Nomenclaturas'
      },
      {
        id: 1016,
        label: 'Relacion Envases Casillero',
        isOpened: false,
        href: '/mantenedores/RelacionEnvasesCasillero'
      },
      {
        id: 1017,
        label: 'Comentarios',
        isOpened: false,
        href: '/mantenedores/Comentarios'
      },
      {
        id: 1018,
        label: 'Relacion SKU Estado',
        isOpened: false,
        href: '/mantenedores/SkuEstado'
      }
    ]
  }
];

const operatorTree = [
  {
    id: 2,
    label: 'Conteos',
    isOpened: false,
    icon: 'count',
    children: [
      {
        id: 2,
        label: 'Conteo Criticidad',
        href: '/conteo-criticidad',
        isOpened: false
      },
      {
        id: 4,
        label: 'Conteo Envases',
        href: '/conteo-envases',
        isOpened: false
      },
      {
        id: 5,
        label: 'Conteo Pallets',
        href: '/conteo-pallet',
        isOpened: false
      }
    ]
  },
  {
    id: 3,
    label: 'Reportes',
    isOpened: false,
    icon: 'report',
    children: [
      {
        id: 3001,
        label: 'Inconsistencia',
        children: [
          {
            id: 30001,
            label: 'Conteo Criticidad',
            href: '/reportes/incosistencias/conteos-criticidad',
            isOpened: false
          },
          {
            id: 30002,
            label: 'Inconsistencia Fecha',
            href: '/reportes/incosistencias/fecha',
            isOpened: false
          }
        ],
        isOpened: false
      },
      /* Generales  */ {
        id: 4001,
        label: 'Generales',
        children: [
          {
            id: 40001,
            label: 'Conteo Monto',
            href: '/reportes/generales/categoria-monto',
            isOpened: false
          },
          {
            id: 40002,
            label: 'Conteo Cantidad',
            href: '/reportes/generales/categoria-conteo-cantidad',
            isOpened: false
          },
          {
            id: 40003,
            label: 'Zona Conteo Monto',
            href: '/reportes/generales/zona-conteo-monto',
            isOpened: false
          },
          {
            id: 40004,
            label: 'Zona Conteo Cantidad',
            href: '/reportes/generales/zona-conteo-cantidad',
            isOpened: false
          },
          {
            id: 40006,
            label: 'SKU Monto',
            href: '/reportes/generales/sku-monto',
            isOpened: false
          },
          {
            id: 40007,
            label: 'SKU Cantidad',
            href: '/reportes/generales/sku-cantidad',
            isOpened: false
          },
          {
            id: 40008,
            label: 'Vencimiento Monto',
            href: '/reportes/generales/vencimiento-conteo-monto',
            isOpened: false
          },
          {
            id: 40009,
            label: 'Vencimiento Cantidad',
            href: '/reportes/generales/vencimiento-conteo-cantidad',
            isOpened: false
          },
          {
            id: 40010,
            label: 'Inventario Criticidad',
            href: '/reportes/generales/inventario-criticidad',
            isOpened: false
          }
        ],
        isOpened: false
      }
    ]
  }
];

type Props = {
  auth: AuthState;
};

const AppNavbar: FunctionComponent<Props> = ({ auth }) => {
  const { isAuthenticated, user } = auth;

  return (
    <div>
      <div>
        {isAuthenticated &&
        (user!.nombreTipoUsuario === TipoUsuarioValues.Admin ||
          [
            TipoUsuarioValues.Admin as string,
            TipoUsuarioValues.Operador as string,
            TipoUsuarioValues.Visualizador as string
          ].includes(user!.nombreTipoUsuario)) ? (
          <div>
            {
              <GenericDropdownMenu
                menu={
                  auth.user?.nombreTipoUsuario === TipoUsuarioValues.Admin
                    ? [...operatorTree.slice(0, 1), ...adminTree, ...operatorTree.slice(1, 2)]
                    : operatorTree
                }
              />
            }{' '}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default connect(({ auth }: AppState) => ({ auth }))(AppNavbar);
